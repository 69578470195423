export const Mentoring = () => {
  return (
    <div id="mentoring" className="content-section-bg">
      <div className="container">
        <div className="w-layout-grid content-grid">
          <div className="content-block bg-text">
            <h2>From 0 to&nbsp;Dev and beyond</h2>
            <p>
              Learning development on my own was not easy but I found a lot of
              people on the way who helped me out without asking anything in
              return... and that changed my life. <br />
              <br />
              With a desire to keep the ball rolling, I have been volunteering
              and helping out beginner coders in their journey through 1o1
              mentorship, code reviews, interview prep and planning the next
              steps in their career.
            </p>
            <a href="#contact" className="button button-space w-button">
              Looking for a Mentor?
            </a>
          </div>
          <div className="image-block">
            <img
              src="./images/mentorship.png"
              sizes="94vw"
              alt="mentorship"
              className="image-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
