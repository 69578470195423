export const OpenSource = () => {
  return (
    <div id="openSource" className="content-section">
      <div className="container">
        <div className="w-layout-grid content-grid">
          <div className="content-block">
            <h2>Open Source</h2>
            <p>
            I am an active contributor to open-source, focusing on implementing modern web technologies, enhancing features, solving bugs, adding tests and reviewing other coders pull requests.
          </p>
            <p>
            I am also passionate about mentoring new contributors, guiding them through their first pull requests, and helping them understand best practices in frontend development. 
            </p>
            <p>
            By contributing to open-source projects, I aim to not only improve my skills but also give back to the community by helping build tools and libraries that benefit developers worldwide.
            </p>
          </div>
          <div className="image-block">
            <img src="./images/osource.png" sizes="94vw" alt=""
              style={{
                width: "500px",  
                borderRadius: "50%",  
                overflow: "hidden", 
              }} 
            />
            <img src="./images/deco.svg" alt=""
              style={{
                marginLeft: "50%",
                position: "absolute",
                marginTop: "20%",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
