import { Nav } from "./Components/Nav"
import { About } from "./Sections/About"
import { Awards } from "./Sections/Awards"
import { Contact } from "./Sections/Contact"
import { Header } from "./Sections/Header"
import { Mentoring } from "./Sections/Mentoring"
import { Volunteering } from "./Sections/Volunteering"
import { WorkExperience } from "./Sections/WorkExperience"
import './style.css';
import { OpenSource } from "./Sections/OpenSource"
// import NavigationMenuDemo from "./Components/Navbar"

function App() {
  return (
    <div className="App">
        <Nav/>
        {/* <NavigationMenuDemo /> */}
        <Header/>
        <About/>
        <WorkExperience/>
        <Awards/>
        <Volunteering/>
        <Mentoring/>
        <OpenSource />
        <Contact/>
    </div>
  );
}

export default App;
