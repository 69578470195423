export const Volunteering = () => {
  return (
    <div id="volunteering" className="content-section">
      <div className="container">
        <div className="w-layout-grid content-grid">
          <div className="image-block">
            <img src="./images/volunteering.png" sizes="94vw" alt="" />
            <img src="./images/deco.svg" alt="" className="pattern" />
          </div>
          <div className="content-block">
            <h2>Volunteering for diversity in Tech</h2>
            <p>
              I have extensive experience in volunteering. I have volunteered at
              Women Who Code, CodeWomen and MigraCode, with the purpose of
              helping the tech world get closer to a more diverse working
              environment.
              <br />
              <br />I especially enjoy volunteering in organizations that
              provide opportunities for learning, finding mentorship, networking
              and growing both professionally and personally by connecting
              people through events, workshops and meetups.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
