export const Header = () => {
  return (
    <div id="header" className="header">
      <div className="container-flex">
        <div className="hero-content">
          <h1
            style={{
              opacity: 1,
              transformStyle: 'preserve-3d',
              transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg)'
            }}            
            className="hero-h1"
          >
            I'm a <span className="brand-span">Software Engineer</span> with a
            passion for product & design.
          </h1>
          <p
            style={{
              opacity: 1,
              transformStyle: 'preserve-3d',
              transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg)'
            }}
            className="hero-paragraph"
          >
            Hi there! I'm <span className="text-span-2">Violeta</span>, I love
            collaborating with people to create amazing products and bring ideas
            to life! Outside of work, I enjoy my free time making music,
            creating art, playing videogames and volunteering.
          </p>
          <div
          style={{
            opacity: 1,
            transformStyle: 'preserve-3d',
            transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg)'
          }}
            className="button-wrapper"
          >
            <a href="#contact" className="button w-button">
              Get in Touch
            </a>
          </div>
        </div>
        <div className="hero-image-wrap">
          <img
            src="./images/avatar.svg"
            alt="Developer"
            style={{
              opacity: 1,
              transformStyle: 'preserve-3d',
              transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg)'
            }}            
            className="hero-image"
          />
        </div>
      </div>
    </div>
  );
};
