export const Contact = () => {
  return (
    <section id="contact" className="cta-section">
      <div className="w-layout-grid cta-grid">
        <div className="cta-image"></div>
        <div className="cta-content">
          <h1 className="cta-heading">
            <span className="dark-span">Let's</span> talk{" "}
          </h1>
          <p className="paragraph-2">
            If you'd like to talk about a job opening at your company,
            mentorship opportunities or just say hello, you can reach out here:
          </p>
          <div className="div-block-2">
            <a
              href="https://www.linkedin.com/in/violetadev"
              className="w-inline-block"
            >
              <img
                src="./images/linkedin.png"
                loading="lazy"
                alt="linkedin"
                className="image-5"
              />
            </a>
            <a
              href="mailto:hello@violeta.dev?subject=Mail%20from%20web"
              className="w-inline-block"
            >
              <img
                src="./images/email.png"
                loading="lazy"
                sizes="45px"
                alt="email"
                className="image-6"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
