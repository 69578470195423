import {  useState } from "react";
import { NavItems } from "./NavItems";
import { createPortal } from "react-dom";

export const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      data-animation="default"
      className="navbar w-nav"
      data-easing2="ease"
      data-easing="ease"
      data-collapse="medium"
      data-w-id="33c883c6-4afc-cc73-3bca-d2857a9d4bc2"
      role="banner"
      data-duration="400"
      id="Navigation"
    >
      {isOpen ? (
        createPortal(
          <div className="navigation-overlay">
            <div onClick={()=>setIsOpen(false)} className="nav-menu-close">⤫</div>
            <NavItems onClose={()=>setIsOpen(false)}/>
          </div>,
          document.body
        )
      ) : (
        <div className="navigation-container">
          <div className="navigation-left">
            <a
              href="/"
              aria-current="page"
              className="brand w-nav-brand w--current"
              aria-label="home"
            >
              <img width="40" src="./images/vlogo.png" alt="" sizes="40px" />
            </a>
          </div>
          <div className="navigation-right">
            <div
              className="menu-button w-nav-button"
              aria-label="menu"
              role="button"
              tabIndex="0"
              aria-controls="w-nav-overlay-0"
              aria-haspopup="menu"
              aria-expanded={isOpen ? "true" : "false"}
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <div className="icon w-icon-nav-menu"></div>
            </div>
            <nav
              role="navigation"
              className={`nav-menu w-nav-menu ${isOpen ? "open" : ""}`}
            >
              <NavItems />
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};
