export const NavItems = ({onClose}) => {
  return (
    <div className="nav-container-links">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className="nav-link w-nav-link" onClick={onClose}>
        Home
      </a>
      <a href="#aboutMe" className="nav-link w-nav-link" onClick={onClose}>
        About me
      </a>
      <a href="#workExperience" className="nav-link w-nav-link" onClick={onClose}>
        Work Experience
      </a>
      <a href="#awards" className="nav-link w-nav-link" onClick={onClose}>
        Awards
      </a>
      <a href="#volunteering" className="nav-link w-nav-link" onClick={onClose}>
        Volunteering
      </a>
      <a href="#openSource" className="nav-link w-nav-link" onClick={onClose}>
        Open Source
      </a>
      <a href="#mentoring" className="nav-link w-nav-link" onClick={onClose}>
        Mentorship
      </a>
      <a href="#contact" className="w-nav-link nav-link" onClick={onClose}>
        Contact me
      </a>
    </div>
  );
};

