export const About = () => {
  return (
    <div id="aboutMe" className="content-section-bg">
      <div className="container">
        <div className="w-layout-grid content-grid">
          <div className="content-block bg-text">
            <h2>Who am I?</h2>
            <p style={{fontSize: "16px"}}>
              I began my career with a strong foundation in the arts, earning a
              degree that honed my creative thinking, communication and management skills. However,
              my passion for technology, which began at age 12 when I started
              coding for fun, eventually led me to transition into software
              development in 2018. This shift allowed me to merge my artistic
              vision with technical expertise, enabling me to create
              pixel perfect and user-focused digital solutions.
            </p>
            <p style={{fontSize: "16px"}}>
              Since changing careers, I've become a full-time software engineer
              with a proven track record in web development, working on
              impactful projects and contributing to the tech community. My
              journey from the arts to technology, underpinned by years of
              coding experience, has equipped me with a unique perspective that
              drives my innovative approach to software design and development.
              <br />
              <br />
            </p>
          </div>
          <div className="image-block" id="about-image">
            <img src="./images/selfphoto.png" alt="" className="image" />
          </div>
        </div>
      </div>
    </div>
  );
};
