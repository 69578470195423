export const WorkExperience = () => {
  return (
    <div id="workExperience" className="content-section">
      <div className="container">
        <div className="title-wrap-centre">
          <h2>Companies I've Developed for...</h2>
        </div>
        <div className="w-layout-grid works-grid">
          <div className="content-card">
            <img
              src="./images/eventbrite-icon.svg"
              alt=""
              className="step-image"
              style={{maxWidth: "50%"}}
            />
            <div className="content-wrapper">
              <h5>Eventbrite</h5>
              <p>
                Software development for XING, the social network for business
                professionals. Typescript, React, NextJS, GraphQL, Apollo,
                Styled Components, Stitches &amp; more.
              </p>
            </div>
          </div>
          <div className="line-column">
            <div className="line"></div>
          </div>
          <div className="content-card">
            <img
              src="./images/xing.svg"
              alt=""
              className="step-image"
              style={{maxWidth: "50%", margin: "20%"}}
            />
            <div className="content-wrapper">
              <h5>New Work SE</h5>
              <p>
                Software development for XING, the social network for business
                professionals. Typescript, React, NextJS, GraphQL, Apollo,
                Styled Components, Stitches &amp; more.
              </p>
            </div>
          </div>
          <div className="line-column">
            <div className="line"></div>
          </div>
          <div className="content-card">
            <img
              style={{maxWidth: "50%", margin: "10%"}}
              src="./images/worldmastery.png"
              alt=""
              className="step-image"
            />
            <div className="content-wrapper">
              <h5>World Mastery</h5>
              <p>
                Web Development for e-learning platforms, admin panel and public
                website. Javascript, Typescript, React, Redux, NextJS, Styled
                Components, SASS, Storybook and more.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="w-layout-grid works-grid">
          <div className="line" id="line-mobile"></div>
          <div className="content-card">
            <img src="./images/nestle.png" alt="" className="step-image" />
            <div className="content-wrapper">
              <h5>Sopra Steria</h5>
              <p>
                Chatbot development for Nestle's brands Nespresso &amp; Purina.
                Facebook and Web integration. NodeJS, DialogFlow, AWS,
                Javascript, React, SASS.
              </p>
            </div>
          </div>
          <div className="line-column">
            <div className="line"></div>
          </div>
          <div className="content-card">
            <img src="./images/travelc.png" alt="" className="step-image" />
            <div className="content-wrapper">
              <h5>Travelclick by Amadeus</h5>
              <p>
                User Interface Design for hotels around the world.
                HTML,&nbsp;CSS, Sketch, Adobe XD &amp; Adobe Photoshop.
              </p>
            </div>
          </div>{" "}
          <div className="content-card"></div>
        </div> */}
      </div>
    </div>
  );
};
